
import { defineComponent } from "vue";
import {
  Button,
  AutoComplete,
  Upload,
  Image,
  message,
  Steps,
  Row,
  Col,
} from "ant-design-vue";
import {
  CloudUploadOutlined,
  Loading3QuartersOutlined,
  CheckCircleFilled,
  FileSearchOutlined,
} from "@ant-design/icons-vue";
import {
  getSingleProductImportTemplateExcel,
  getBrandImportTemplateExcel,
  singleProductImport,
  brandImport,
  singleproductGetImportStatus,
  BrandGetImportStatus,
  productGetErrorMsgUrl,
  singleproductReplaceImportStatus,
  brandReplaceImportStatus,
  templateUpload,
} from "@/api/terminalList";
const { Step } = Steps;
export default defineComponent({
  components: {
    Steps,
    Step,
    Row,
    Col,
    Upload,
    CloudUploadOutlined,
    Loading3QuartersOutlined,
    CheckCircleFilled,
    FileSearchOutlined,
    Button,
  },
  data() {
    return {
      current: 0,
      importExcel: false,
      steps: [
        {
          title: "上传文件",
        },
        {
          title: "验证导入",
        },
        {
          title: "完成",
        },
      ],
      height: "500px",
      notUploaded: true,
      fileList: [],
      nowDate: "20211014",
      importSucceeded: false,
      addNum: 0,
      allNum: 0,
      failNum: 0,
      //下载模板路径
      templateUrl: "",
      //要上传的Excel文件
      file: {},
      timer: "",
      errUrl: "",
      templateUpload: templateUpload,
    };
  },
  methods: {
    //上传图片
    handleChange(info) {
      this.notUploaded = false;
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 文件上传失败`);
      }
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验文件格式
    beforeUpload(file, fileList) {
      this.file = file;
      const isExcel = file.name.includes("xls");
      if (!isExcel) {
        message.error("上传文件应为xls或xlsx格式,请重新上传", 4);
        this.importExcel = true;
        fileList.length = 0;
      } else {
        this.importExcel = false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error("文件大小不能大于10M,如数量过多请分批上传", 4);
        fileList.length = 0;
      }
      // return isJpgOrPng && isLt10M;
      return isLt10M && isLt10M;
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month: any = date.getMonth() + 1;
      var strDate: any = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    submit() {
      if (this.fileList.length == 0) {
        message.warning("请先上传文件");
        return;
      }
      this.current++;
      const formData = new FormData();
      formData.append("file", this.file as any);
      formData.append("key", "BRAND" as any);
      brandImport(formData)
        .then((res) => {
          if (res.data.success) {
            (this as any).timer = setInterval(this.getStatus, 5000);
            this.current++;
            this.addNum = res.data.data.addNum;
            this.allNum = res.data.data.allNum;
            this.failNum = res.data.data.failNum;
          } else {
            this.current--;
            //  message.error(res.data.errorMessage);
          }
        })
        .catch(() => {
          this.current--;
        });
    },
    goback() {
      history.go(-1);
    },
    continueImport() {
      brandReplaceImportStatus("BRAND").then((res: any) => {
        if (res.data.success) {
          (this as any).getStatus();
          (this as any).fileList = [];
          (this as any).notUploaded = true;
        }
      });
    },
    //下载模板
    DownloadTemplate() {
      window.open(this.templateUrl);
    },
    //获取当前导入状态
    getStatus(type) {
      BrandGetImportStatus("BRAND")
        .then((res: any) => {
          if (res.data.success) {
            this.current = res.data.data.status;
            this.addNum = res.data.data.addNum;
            this.allNum = res.data.data.allNum;
            this.failNum = res.data.data.failNum;
            this.errUrl = res.data.data.failMsgUrl;
            if (
              res.data.data.status == 2 &&
              (this as any).$route.fullPath.includes("batchImport")
            ) {
              brandReplaceImportStatus("BRAND");
            }
            if (this.current !== 1) {
              clearInterval((this as any).timer);
              (this as any).timer = null;
            }
          } else {
            clearInterval((this as any).timer);
            (this as any).timer = null;
          }
        })
        .catch(() => {
          clearInterval((this as any).timer);
          (this as any).timer = null;
        });
    },
    //处理错误文件下载地址
    handleErrUrl(errUrl) {
      return location.origin + productGetErrorMsgUrl() + errUrl;
    },
  },
  mounted() {
    this.height = window.screen.height - 375 + "px";
    //获取当前日期
    (this as any).nowDate = this.getDate();
    //获取模板路径
    getBrandImportTemplateExcel("BRAND").then((res) => {
      console.log(888, res.data.data);
      this.templateUrl = res.data.data;
      // if (res.data.success) {
      //   this.templateUrl = res.data.data.absoluteFileUrl;
      // }
      // else {
      //   message.error("");
      // }
    });
    this.getStatus(1);
    (this as any).timer = setInterval(this.getStatus, 5000);
  },
  unmounted() {
    clearInterval((this as any).timer);
  },
});
